<template>
  <div id="meet-the-freelancer" class="mb-0 mb-md-4 pt-4 pb-4 pb-sm-5">
    <div class="container">
      <div v-b-toggle.seller-collapse class="mobile-toggle w-100 d-flex d-sm-none position-relative">
        <h3 class="font-weight-bold text-left mt-2 px-3 py-1">{{ $t('meet_the_sellers.title') }}</h3>
        <i class="fa-solid position-absolute" :class="visible ? 'fa-caret-down' : 'fa-caret-left'" aria-hidden="true"></i>
      </div>
      <h3 class="font-weight-bold pt-2 pb-1 mb-3 d-none d-sm-block">{{ $t('meet_the_sellers.title') }}</h3>
      <b-collapse id="seller-collapse" v-model="visible" class="mt-3 pt-1">
        <div class="row">
          <div class="col-12 col-md-4 mb-4 mb-md-0" v-for="(seller, index) in sellers" v-bind:key="index">
            <div class="card card-fade h-100 shadow">
              <div class="card-front">
                <b-img-lazy :src="seller.image.front" class="card-img rounded"/>
                <div class="card-img-overlay d-flex flex-column justify-content-center overlay-dark rounded">
                  <h3 class="card-title font-weight-bold text-white">{{ seller.section }}</h3>
                </div>
              </div>
              <div class="card-back bg-denim text-white">
                <b-img-lazy :src="seller.image.back" class="card-img rounded"/>
                <div class="card-body card-img-overlay d-flex flex-column align-items-baseline rounded overflow-hidden">
                  <h3 class="text-left font-weight-bold mb-0">{{ seller.username }}</h3>
                  <small class="text-left d-block">{{ seller.title }}</small>
                  <button class="btn btn-outline-primary mt-auto" @click="open(seller.username)">
                    {{ $t('read_more') }}
                  </button>
                  <div class="shadow position-absolute w-100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <seller-information-modal ref="sellerInfoModel"/>
  </div>
</template>
<script>
import SellerInformationModal from '@/components/Modal/SellerInformationModal'

export default {
  name: 'MeetTheSeller',
  components: { SellerInformationModal },
  data () {
    return {
      'sellers': [
        {
          'section': this.$t('any_video'),
          'username': 'Ivanstudio',
          'title': this.$t('meet_the_sellers.sellers.ivanstudio.title'),
          'image': {
            'front': require('../../assets/MeetTheSellers/ivanstudio/ivanstudios.jpg'),
            'back': require('../../assets/MeetTheSellers/ivanstudio/ivanstudios_cutout.png')
          }
        },
        {
          'section': this.$t('any_marketing'),
          'username': 'Writingpro',
          'title': this.$t('meet_the_sellers.sellers.writingpro.title'),
          'image': {
            'front': require('../../assets/MeetTheSellers/writingpro/writingpro.jpg'),
            'back': require('../../assets/MeetTheSellers/writingpro/writingpro_cutout.png')
          }
        },
        {
          'section': this.$t('any_design'),
          'username': 'Helion',
          'title': this.$t('meet_the_sellers.sellers.helion.title'),
          'image': {
            'front': require('../../assets/MeetTheSellers/helion/helion.jpg'),
            'back': require('../../assets/MeetTheSellers/helion/helion_cutout.png')
          }
        }
      ],
      visible: false
    }
  },
  methods: {
    open (username) {
      this.$refs['sellerInfoModel'].open(username.toLowerCase())
    }
  }
}
</script>

<style lang="scss" scoped>
#meet-the-freelancer ::v-deep {

  @include mobile-mid-up {
    .mobile-toggle {
      h3 {
        width: 72%;
      }
    }
  }

  @include sm-up {
    .card-back {
      opacity: 1;
    }
    .card-front {
      opacity: 0;
    }
    .collapse {
      display: block !important;
    }
  }

  @include md-up {
    .card-back {
      opacity: 0;
    }
    .card-front {
      opacity: 1;
    }
  }

  .row {
    .col-12 {
      &:nth-child(1) {
        .card-back {
          img {
            transform: translateX(80px)
          }
        }
      }
      &:nth-child(2) {
        .card-back {
          img {
            transform: translateX(50px)
          }
        }
      }
      &:nth-child(3) {
        .card-back {
          img {
            transform: translateX(60px)
          }
        }
      }
      .card-fade {
        overflow: hidden;
        &:hover {
          .card-back {
            opacity: 1;
          }
        }
      }
      .card-back {
        transition: 0.4s ease-in-out;
        position: absolute;
        top: 0;
        .btn {
          z-index: 9;
        }
        .shadow {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: transparent;
          background: linear-gradient(180deg, rgba(0,0,0,0) 62%, rgba(0,0,0,.5) 100%);
        }
      }
    }
  }

  .overlay-dark {
    background-color: rgba(43,43,43, 0.4);
  }

  .bg-denim {
    background-color: $brand-denim;
  }

  .mobile-toggle {
    border: 1px solid $brand-denim;
    border-radius: 8px;
    i {
      font-size: 1.5rem;
      right: 14px;
      top: 32px;
    }
  }
}
</style>
