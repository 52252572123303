<template>
  <div>
    <h1 class="font-weight-bold text-center mb-3">{{ $t('become_seller.how_it_works.title') }}</h1>
    <div id="how-to" class="d-flex flex-col flex-md-row justify-space-around position-relative mb-3">
      <div class="progress position-absolute d-none d-md-flex">
        <span class="d-flex position-relative overflow-hidden"></span>
      </div>
      <div class="col-12 col-md-3 mb-4 mb-md-0 step">
        <div class="d-inline-flex flex-column">
          <div class="icon-holder p-4 mx-auto">
            <svg class="first" x="0px" y="0px" viewBox="0 0 120 120" style="enable-background: new 0 0 120 120;" xml:space="preserve">
              <path class="st0" d="M102.3,93.8H17.7c-2.2,0-4-1.8-4-4V30.2c0-2.2,1.8-4,4-4h84.7c2.2,0,4,1.8,4,4v59.6C106.3,92,104.6,93.8,102.3,93.8z"/>
              <polyline class="st0" points="52.2,59.3 60,66.3 76.5,44.9"/>
              <path class="st0" d="M79.3,58c0.1,0.6,0.1,1.2,0.1,1.9c0,10.7-8.7,19.4-19.4,19.4s-19.4-8.7-19.4-19.4S49.3,40.4,60,40.4c2.6,0,5,0.5,7.3,1.4"/>
            </svg>
          </div>
          <span class="mt-2 mt-md-3">{{ $t('become_seller.how_it_works.steps.0') }}</span>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-4 mb-md-0 step">
        <div class="d-inline-flex flex-column">
          <div class="icon-holder p-4 mx-auto">
            <svg class="second" x="0px" y="0px" viewBox="0 0 120 120" style="enable-background: new 0 0 120 120;" xml:space="preserve">
              <path class="st0" d="M45.6,22.3H30.7c-2.3,0-4.2,1.9-4.2,4.2v74.9c0,2.3,1.9,4.2,4.2,4.2h59c2.3,0,4.2-1.9,4.2-4.2V26.5c0-2.3-1.9-4.2-4.2-4.2H75.1"/>
              <path class="st0" d="M76.1,27.7H44.7c0-8.7,7-15.7,15.7-15.7S76.1,19,76.1,27.7z"/>
              <line class="st0" x1="50.5" y1="41.8" x2="86.1" y2="41.8"/>
              <line class="st0" x1="50.4" y1="58.8" x2="86" y2="58.8"/>
              <line class="st0" x1="50.4" y1="75.8" x2="78.8" y2="75.8"/>
              <line class="st0" x1="51" y1="92.9" x2="79.4" y2="92.9"/>
              <polyline class="st0" points="35.8,42.8 38.6,45.7 44.4,37.2 "/>
              <polyline class="st0" points="35.8,60.4 38.6,63.2 44.4,54.7 "/>
              <polyline class="st0" points="35.8,77.1 38.6,80 44.4,71.5 "/>
              <polyline class="st0" points="35.8,94.2 38.6,97.1 44.4,88.6 "/>
              <path class="st0" d="M75.1,22.3"/>
              <path class="st0" d="M45.6,22.3"/>
            </svg>
          </div>
          <div class="mt-2 mt-md-3">{{ $t('become_seller.how_it_works.steps.1') }}</div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-4 mb-md-0 step">
        <div class="d-inline-flex flex-column">
          <div class="icon-holder p-4 mx-auto">
            <svg class="third" x="0px" y="0px" viewBox="0 0 120 120" style="enable-background: new 0 0 120 120;" xml:space="preserve">
              <path class="st0" d="M60,111.9L60,111.9c-12,0-21.8-9.8-21.8-21.8V66.1c0-12,9.8-21.8,21.8-21.8h0c12,0,21.8,9.8,21.8,21.8v24.1C81.8,102.2,72.1,111.9,60,111.9z"/>
              <path class="st0" d="M38.2,70.3c2.2,2,7.1,7.8,21.8,7.8c14.6,0,19.6-5.5,21.8-7.5"/>
              <path class="st0" d="M60,54.7V33.8c0,0,1.2-10.9-11-10.9S38.2,12.6,38.2,9.9s0-1.7,0-1.7"/>
              <path class="st0" d="M60.1,54.7h-0.2c-2.4,0-4.3,1.9-4.3,4.3v4.4c0,2.4,1.9,4.3,4.3,4.3h0.2c2.4,0,4.3-1.9,4.3-4.3V59C64.4,56.6,62.5,54.7,60.1,54.7z"/>
              <line class="st0" x1="60.1" y1="67.6" x2="60.1" y2="78.1"/>
            </svg>
          </div>
          <div class="mt-2 mt-md-3">{{ $t('become_seller.how_it_works.steps.2') }}</div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-4 mb-md-0 step">
        <div class="d-inline-flex flex-column">
          <div class="icon-holder p-4 mx-auto">
            <svg class="fourth" x="0px" y="0px" viewBox="0 0 120 120" style="enable-background:new 0 0 120 120;" xml:space="preserve">
              <path class="st0" d="M46.1,98.8H16.7c-0.4,0-0.8-0.4-0.8-0.8V50.8c0-0.4,0.4-0.8,0.8-0.8h29.4c0.4,0,0.8,0.4,0.8,0.8V98C46.9,98.4,46.5,98.8,46.1,98.8z"/>
              <path class="st0" d="M46.9,92.9c13.9,8.4,27.7,8.2,27.7,8.2s8.5,0.1,13.8,0.1c5.2,0,8.1-1.4,8.6-6.2s-5.7-5.9-5.7-5.9l-3.1-0.1c0,0,6.1,0,7.1,0s5.2-0.6,5.2-6.6c0-5.9-4.3-6-4.3-6l-7.4-0.1l8.2,0c3,0,5.5-2.8,5.5-6.2c0-3.4-2.5-6.2-5.5-6.2l-7.8-0.1h8.5c3.5,0,6.4-2.9,6.4-6.4s-2.9-6.4-6.4-6.4H80.9c0,0-3.6,0.2-2.9-4.1s2.2-18.5,2.2-19.8c0-1.3,1.3-7.5-4.6-8.4c-6-0.8-7.3,4.4-7.6,6.1c-0.2,1.7-1.3,10-4.6,14.4c-3.3,4.5-2.9,3.9-6.1,7.4c-3.2,3.5-6.7,4.3-10.3,9.5"/>
            </svg>
          </div>
          <span class="mt-2 mt-md-3">{{ $t('become_seller.how_it_works.steps.3') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss">

  $draw-in-length: 2s;

  #how-to {
    .progress {
      height: 1px;
      background: transparent;
      left: 15%;
      right: 15%;
      top: 63px;
      span {
        width: 0;
        animation: expandWidth 3s linear forwards;
        animation-delay: 1s;
        height: 1px;
        background-color: $brand-sky;
      }
    }
    .step {
      opacity: 0;
      animation-name: fadeInUp;
      animation-duration: .5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      svg {
        animation: dash $draw-in-length linear forwards;
      }
      &:nth-of-type(2) {
        animation-delay: 0.5;
        svg {
          animation-delay: .75s;
        }
      }
      &:nth-of-type(3) {
        animation-delay: 1.5s;
        svg {
          animation-delay: 1.75s;
        }
      }
      &:nth-of-type(4) {
        animation-delay: 2.5s;
        svg {
          animation-delay: 2.75s;
        }
      }
      &:nth-of-type(5) {
        animation-delay: 3.5s;
        svg {
          animation: dash ($draw-in-length - .5) + 0s linear forwards;
          animation-delay: 3.75s;
        }
      }
      .icon-holder {
        border: 1px solid $brand-sky;
        border-radius: 50%;
        background: white;
        svg {
          width: 80px;
          height: 80px;
          stroke-width: 3;
          fill: none;
          stroke: $brand-sky;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
          stroke-dasharray: 460;
          stroke-dashoffset: 460;
        }
      }
    }
  }

</style>
