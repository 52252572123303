<template>
  <b-modal ref="etn-modal" id="etn-modal" size="xl" :hide-footer=true :hide-header=true>
  <div class="d-flex flex-column">
    <div class="banner d-flex align-content-center justify-content-center">
      <div @click="$bvModal.hide('etn-modal')" class="close position-absolute cursor-pointer">
        <i class="fa-solid fa-xmark" aria-hidden="true"></i>
      </div>
      <img :src="require('@/assets/BecomeASeller/electroneum-logo-light.svg')" class="logo d-flex">
    </div>
    <div class="copy px-4 px-sm-5 py-3">
      <h3 class="font-weight-bold mt-3 mb-4">{{ $t('become_seller.etn_modal.section_one.title1') }}</h3>
      <p>{{ $t('become_seller.etn_modal.section_one.p3') }}</p>
      <p>{{ $t('become_seller.etn_modal.section_one.p4') }}</p>
      <div class="d-flex flex-row pay-for mb-1">
        <div class="p-2 mb-2 mr-2 font-weight-bold">{{ $t('become_seller.etn_modal.section_one.uses.first') }}</div>
        <div class="p-2 mb-2 mr-2 font-weight-bold">{{ $t('become_seller.etn_modal.section_one.uses.second') }}</div>
        <div class="p-2 mb-2 mr-2 font-weight-bold">{{ $t('become_seller.etn_modal.section_one.uses.third') }}</div>
        <div class="p-2 mb-2 mr-2 font-weight-bold">{{ $t('become_seller.etn_modal.section_one.uses.fourth') }}</div>
        <div class="p-2 mb-2 mr-2 font-weight-bold">{{ $t('become_seller.etn_modal.section_one.uses.fifth')}}</div>
        <div class="p-2 mb-2 mr-2 font-weight-bold">{{ $t('become_seller.etn_modal.section_one.uses.sixth') }}</div>
      </div>
      <p class="mb-5">{{ $t('become_seller.etn_modal.section_one.uses.more')}}</p>
      <h3 class="font-weight-bold my-4">{{ $t('become_seller.etn_modal.section_two.title')}}</h3>
      <p>{{ $t('become_seller.etn_modal.section_two.p1')}}</p>
      <p v-html="$t('become_seller.etn_modal.section_two.p2', { link: 'https://electroneum.com/' })"></p>
    </div>
    <div class="d-flex flex-col flex-sm-row px-4 px-sm-0 mb-4 justify-content-center">
      <a href="https://electroneum.com" target="_blank" rel="noopener noreferrer" class="btn btn-primary px-4 py-2 my-2 mr-0 mr-sm-3">{{ $t('become_seller.etn_modal.section_two.visit_btn')}}</a>
      <b-button variant="outline-primary" @click="$bvModal.hide('etn-modal')" class="px-4 py-2 my-2">{{ $t('close_button')}}</b-button>
    </div>
  </div>
</b-modal>
</template>

<script>
export default {
  name: 'ElectroneumInfo'
}
</script>

<style lang="scss">

  #etn-modal {
    .modal-dialog {
      max-width: 960px;
      .modal-body {
        padding: 0;
        .banner {
          background: url('~@/assets/BecomeASeller/etn-modal-header.jpg');
          background-size: cover;
          border-bottom-left-radius: 100% 20%;
          border-bottom-right-radius: 100% 20%;
          min-height: 220px;
          .close {
            color: white;
            right: 20px;
            top: 20px;
          }
          .logo {
            position: relative;
            width: 240px;
          }
        }
        .copy {
          a {
            color: $brand-sky;
          }
          .pay-for {
            div {
              border-radius: .5rem;
              border: 1px solid $brand-navy;
            }
          }
        }
        .btn-primary {
          a {
            color: white;
            &:hover {
              text-decoration: none;
              color: $brand-sky !important;
            }
          }
          &:hover {
            background: transparent !important;
            border-color: $brand-sky !important;
            color: $brand-sky !important;
            a {
              text-decoration: none;
              color: $brand-sky !important;
            }
          }
        }
      }
    }
  }
  @include sm-up {
    #etn-modal {
      .modal-dialog {
        max-width: 960px;
        .modal-body {
          .banner {
            .logo {
              position: absolute;
              right: 100px;
              top: 80px;
            }
          }
        }
      }
    }
  }

</style>
