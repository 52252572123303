<template>
  <div>
    <b-modal :id="modalId" ref="my-modal" size="md" @shown="shown" @hide="resetData" @hidden="$store.dispatch('removeSignUpErrors')" hide-footer hide-header>
      <template #default="{ close }">

        <div @click="close()" class="close position-absolute cursor-pointer">×</div>

        <div class="p-4">
          <h2 class="font-weight-bold" v-html="sellerModal ? $t('become_seller_join_title') : $t('join_anytasks')"/>
          <p class="m-2">{{ stage === 1 ? $t('join_message') : sellerModal ? $t('join_username_message') : $t('join_full_detail_message') }}</p>
          <div v-if="$store.state.register.signUpErrors !== null && $store.state.register.signUpErrors.length > 0">
            <p v-for="(error, index) in $store.state.register.signUpErrors" :key="index" class="alert alert-danger p-2">
              <span v-if="error === 'HTTP-401'">
                <i class="fa-solid fa-triangle-exclamation"></i>
                {{ $t('sign_up_email_error') }} <a v-on:click="toggleModal('forgotPassword')"> {{ $t('sign_up_forgotten_password') }}</a>
              </span>
              <span v-else>
                <i class="fa-solid fa-triangle-exclamation"></i>
                {{ error }}
              </span>
            </p>
          </div>

          <b-form @submit="submit" class="pt-2">
            <b-form-group id="input-group-1" label-for="input-1" v-if="stage === 1">
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                required
                :placeholder="$t('enter_email')"
              />
            </b-form-group>

            <b-form-group
              id="input-group-3" label-for="input-3" class="text-center"
              :description="$t('password_description')" v-if="stage === 1">
              <b-input-group class="mt-3">
                <b-form-input id="input-3"
                              v-model="form.password"
                              :type="!showPassword ? 'password' : 'text'"
                              required
                              @input="applyPasswordStrength(form.password)"
                              :placeholder="$t('choose_password')"/>
                <b-input-group-append>
                  <b-button variant="outline-secondary" class="eyeBorder" v-on:click="switchPassword()">
                    <i class="fa-solid fa-fw" v-bind:class="{'fa-eye' : !showPassword, 'fa-eye-slash' : showPassword }" aria-hidden="true"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <meter min="0" max="4" id="password-strength-meter" ref="passwordStrengthMeter"/>
              <p id="password-strength-text" class="mb-0" ref="passwordStrengthText" v-if="stage === 1"/>
            </b-form-group>

            <b-form-checkbox
              v-if="stage === 1"
              id="general"
              v-model="form.general"
              type="checkbox"
              value="accepted"
              :disabled="stage === 2"
              class="mb-2"
            >
              <small v-html="$t('newsletter_modal.option_one')"/>
            </b-form-checkbox>

            <b-form-checkbox
              v-if="stage === 1"
              id="news"
              v-model="form.news"
              type="checkbox"
              value="accepted"
              :disabled="stage === 2"
              class="mb-2"
            >
              <small v-html="$t('newsletter_modal.option_two')"/>
            </b-form-checkbox>

            <b-form-checkbox
              v-if="stage === 1"
              id="terms"
              v-model="form.terms"
              type="checkbox"
              value="accepted"
              :disabled="stage === 2"
              class="mb-2"
            >
              <small v-html="$t('accept_terms', { terms_link: '/terms-and-conditions' })"/>
            </b-form-checkbox>

            <b-form-input
              v-if="stage === 2"
              id="username-field"
              v-model="username"
              type="text"
              required
              :placeholder="$t('choose_username')"
              class="mt-2"
              @keypress="usernameInputCheck"
              v-on:blur="changeText"
            ></b-form-input>

            <p class="mt-2" v-if="stage === 2">
              {{ $t('suggestion') }}
              <span v-if="!showSuggestions" class="spinner-border spinner-border-sm ml-2"/>
              <span class="pr-1" v-else v-for="(username, index) in usernameSuggestionArray" :key="index">
                <a v-on:click="addUsername(username)" class="text-primary">{{ username }}</a>
                {{ index !== 2 ? ' , ' : '' }}
              </span>
            </p>
            <div class="field-heading mt-3" v-if="stage === 2 && displayAccountType">{{ $t('account_type.header') }}</div>

            <b-form-group required class="mt-2" v-if="stage === 2 && displayAccountType">
              <b-form-radio v-model="account_type" class="radio" :value="0">{{ $t('account_type.seller') }}</b-form-radio>
              <b-form-radio v-model="account_type" class="radio" :value="1">{{ $t('account_type.buyer') }}</b-form-radio>
            </b-form-group>
            <small v-show="stage === 2 && account_type === 0">
              Please note that the cryptoasset services provided by the AnyTask Platform are outside the scope of the jurisdiction and protection of the Financial Ombudsman Service
              and the Financial Services Compensation Scheme.
            </small>

            <div class="text-center px-3 py-2" v-show="stage === 1">
              <small v-html="$t('join_recaptcha')"></small>
            </div>

            <b-form-group id="input-group-4" class="m-0 mt-3">
              <div v-if="showSpinner">
                <b-button type="submit" class="w-100 py-2" variant="primary" disabled="disabled">
                  <b-spinner class="" small label="Large Spinner"/>
                </b-button>
              </div>
              <div v-else>
                <b-button type="submit" class="w-100 py-2" variant="primary">
                  {{ stage === 1 ? $t('join') : $t('join_stage_2_button') }}
                </b-button>
              </div>
            </b-form-group>
          </b-form>
        </div>

        <div slot="modal-footer" class="w-100 border-top pt-3 pb-3 text-center bg-light" v-if="stage === 1">
          <p class="text-dark mb-2">{{ $t('already_member') }}</p>
          <a v-on:click="toggleModal('login')" class="btn btn-warning text-">{{ $t('sign_in') }}</a>
        </div>

      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ApiService from '../../apiService'
import config from '../../config'
import store from '../../store'

export default {
  name: 'signup',
  data () {
    return {
      form: {
        email: '',
        password: '',
        terms: false,
        general: false,
        news: false,
        capture: ''
      },
      displayAccountType: true,
      username: '',
      account_type: null,
      showPassword: false,
      result: 0,
      show: true,
      strength: {
        0: this.$t('password-strength-worst'),
        1: this.$t('password-strength-bad'),
        2: this.$t('password-strength-weak'),
        3: this.$t('password-strength-good'),
        4: this.$t('password-strength-strong')
      },
      submitButton: document.getElementById('sign-up'),
      showSpinner: false,
      showSpinnerEtn: false,
      stage: 1,
      usernameSuggestionArray: [],
      showSuggestions: false
    }
  },
  props: {
    sellerModal: {
      type: Boolean,
      required: false,
      default: false
    },
    modalId: {
      type: String,
      required: false,
      default: 'signup'
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'signup' || modalId === 'sellerSignUpModal') {
        localStorage.removeItem('signUpType')
        localStorage.removeItem('signUpTypeCustom')
        if (this.stage === 2 && !this.showSpinner) {
          store.dispatch('signOut')
        }
      }
    })
    this.$root.$on('bv::modal::show', async (bvEvent, modalId) => {
      if (modalId === 'signup' || modalId === 'sellerSignUpModal') {
        if (['purchase', 'purchaseTask'].includes(localStorage.getItem('signUpType')) || modalId === 'sellerSignUpModal') {
          this.displayAccountType = false
          this.account_type = modalId === 'sellerSignUpModal' ? 0 : 1
        } else {
          this.displayAccountType = true
        }
      }
    })
  },
  computed: {
    ...mapState({
      signingUp: state => state.register.signingUp,
      signUpErrors: state => state.register.signUpErrors
    })
  },
  methods: {
    ...mapActions([
      'doLoginEtn',
      'processJwt'
    ]),
    resetData () {
      this.form = {
        email: '',
        password: '',
        terms: false,
        general: false,
        news: false
      }
      this.username = ''
      this.stage = 1
      this.showSpinner = false
      if (!this.displayAccountType) {
        this.account_type = this.modalId === 'sellerSignUpModal' ? 0 : 1
      } else {
        this.account_type = null
      }
    },
    shown () {
      this.resetData()
      if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'registrationInitiate'
        })
      }
    },
    switchPassword () {
      this.showPassword = this.showPassword !== true
    },
    addUsername (username) {
      this.username = username
    },
    usernameInputCheck (evt) {
      if (/[^A-Za-z0-9]+/g.exec(evt.key)) {
        evt.preventDefault()
      } else if (this.username.length === 15) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changeText () {
      this.username = this.username.toLowerCase()
    },
    usernameSuggestions () {
      ApiService.getUsernameSuggestions().then((data) => {
        this.usernameSuggestionArray = data.data.data.slice(0, 3)
        this.showSuggestions = true
      })
    },
    validateForm () {
      this.$store.state.register.signUpErrors = []

      if (this.form.email.length === 0) {
        this.$store.state.register.signUpErrors.push(this.$t('join_validator_email'))
      }

      if (this.form.password.length > 0 && this.checkPasswordStrength(this.form.password) < 2) {
        this.$store.state.register.signUpErrors.push(this.$t('join_validator_password_weak'))
      }

      if (!this.form.terms) {
        this.$store.state.register.signUpErrors.push(this.$t('join_validator_terms'))
      }
      return this.$store.state.register.signUpErrors.length === 0
    },
    submit (e) {
      e.preventDefault()
      if (this.stage === 1) {
        this.register()
      } else {
        this.updateUser()
      }
    },
    patchValidation () {
      this.$store.state.register.signUpErrors = []
      if (this.username.length === 0) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_username_null'))
      } else if (this.username.length < 3) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_username_too_short'))
      } else if (this.username.length > 15) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_username_too_long'))
      }

      if (this.account_type === null && this.displayAccountType) {
        this.$store.state.register.signUpErrors.push(this.$t('first_time_login_error_account_type_not_selected'))
      }

      return this.$store.state.register.signUpErrors.length === 0
    },
    updateUser () {
      if (this.patchValidation()) {
        this.showSpinner = true
        ApiService.patchUser({ username: this.username, account_type: this.account_type }).then((data) => {
          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'registrationCompletion',
              'registeredIntent': !this.account_type || this.account_type === '0' ? 'seller' : 'buyer'
            })
          }

          if (config.tracking_enabled === 'true') {
            window.__at_px.fire('register', !this.account_type || this.account_type === '0' ? 'seller' : 'buyer')
          }

          if (config.tracking_enabled === 'true') {
            let el = document.createElement('img')
            el.src = 'https://tags.w55c.net/rs?id=b53fd3098e2044a5b75179dd870a2c45&t=marketing&sku=' + (!this.account_type || this.account_type === '0' ? 'seller' : 'buyer')
            document.body.append(el)
          }

          localStorage.setItem('accessToken', data.data.data.token)
          this.processJwt()

          this.$store.dispatch('removeLoginError').then(() => {
            if (this.account_type === 0) {
              this.$router.push({ name: 'seller-welcome' })
            } else if (localStorage.getItem('signUpType') === 'purchase') {
              this.$store.state.register.signUpComplete.push('purchase')
            } else if (localStorage.getItem('signUpTypeCustom') === 'custom-quote') {
              this.$store.state.register.signUpComplete.push('custom-quote')
            }
            this.$bvModal.hide(this.modalId)
          })
        }).catch((error) => {
          let errors = error.response.data.errors.children
          if (errors.username.errors) {
            errors.username.errors.forEach(function (child) {
              this.$store.state.register.signUpErrors.push(child)
            }.bind(this))
          }
          if (errors.account_type.errors) {
            errors.account_type.errors.forEach(function (child) {
              this.$store.state.register.signUpErrors.push(child)
            }.bind(this))
          }
          this.showSpinner = false
        })
      }
    },
    async register () {
      if (this.validateForm()) {
        this.showSpinner = true
        this.refreshState()

        await this.$recaptcha('signup').then((token) => {
          this.form.capture = token
        })

        const payload = {
          ...{
            email: this.form.email,
            password: {
              first: this.form.password,
              second: this.form.password
            },
            marketing_general: (this.form.general ? 1 : 0),
            marketing_news: (this.form.news ? 1 : 0),
            terms_accepted: (this.form.terms ? 1 : 0),
            capture: this.form.capture
          },
          ...JSON.parse(localStorage.getItem('utm'))
        }

        this.$store.dispatch('register', payload)
          .then(() => {
            if (this.$store.state.accessToken !== null) {
              if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  'event': 'registrationDetails'
                })
                if (this.$route.name === 'BecomeSeller') {
                  window.dataLayer.push({
                    'event': 'sellerDetails'
                  })
                }
              }
              localStorage.removeItem('utm')
              this.stage = 2
              this.usernameSuggestions()
            }
            this.showSpinner = false
          })
      }
    },
    toggleModal (modal) {
      if (localStorage.getItem('signUpType') === 'purchase') {
        this.$bvModal.hide(this.modalId)
        localStorage.setItem('signUpType', 'purchaseTask')
      } else {
        this.$bvModal.hide(this.modalId)
        localStorage.setItem('signUpTypeCustom', 'custom-quote')
      }
      this.$bvModal.show(modal)
    },
    scorePassword (password) {
      let score = 0

      if (!password) {
        return score
      }

      let letters = {}
      for (let i = 0; i < password.length; i++) {
        letters[password[i]] = (letters[password[i]] || 0) + 1
        score += 5.0 / letters[password[i]]
      }

      let variations = {
        digits: /\d/.test(password),
        lower: /[a-z]/.test(password),
        upper: /[A-Z]/.test(password),
        nonWords: /\W/.test(password)
      }

      let variationCount = 0
      for (let check in variations) {
        variationCount += (variations[check]) ? 1 : 0
      }
      score += (variationCount - 1) * 10
      return Math.trunc(score)
    },
    checkPasswordStrength (password) {
      let score = this.scorePassword(password)
      let idx = 0
      if (score > 90) {
        idx = 4
      } else if (score > 70) {
        idx = 3
      } else if (score >= 40) {
        idx = 2
      } else if (score >= 20) {
        idx = 1
      }
      return idx
    },
    applyPasswordStrength (password) {
      this.result = this.checkPasswordStrength(password)
      let meter = this.$refs.passwordStrengthMeter
      let text = this.$refs.passwordStrengthText
      meter.value = this.result

      if (password !== '') {
        text.innerHTML = this.$t('password-strength') + ': ' + '<strong>' + this.strength[this.result] + '</strong>' + '<span class=\'feedback\'></span>'
      } else {
        text.innerText = ''
      }
    },
    refreshState () {
      this.$store.state.register.signUpErrors = null
    }
  }
}
</script>

<style>
#sellerSignUpModal .modal-body,
#signup .modal-body {
  padding: 0;
}

#signup___BV_modal_title_.modal-title {
  width: 100%;
  text-align: center;
  font-size: 1.6em;
}

.modal {
  padding-left: 0 !important;
}
</style>
<style scoped>
.close {
  right: 8px;
  top: 5px;
}

.eyeBorder,
.eyeBorder:active,
.eyeBorder:hover,
.eyeBorder:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}

small {
  color: #999;
}

span.footer-member {
  color: #999;
  font-weight: 300;
}

.etn img {
  height: 2rem;
  padding-bottom: 2px;
}

.col {
  width: 100%;
  padding: 0;
}

.col a.btn {
  width: 100%;
}

span hr {
  height: 1px;
  border-radius: 5%;
  border: none;
  background-color: #999;
  margin-top: 10px;
  width: 100%;
}

span small {
  text-align: center;
  color: #999;
  font-weight: 400;
}

meter {
  margin: 0.8em auto 0.4em;
  width: 100%;
  height: .5em;
}

meter[value="1"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbdb0f', endColorstr='#fbdb0f', GradientType=0);
  /* IE6-9 */
}

meter[value="2"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="3"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="4"]::-webkit-meter-optimum-value {
  background: -moz-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="1"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fbdb0f 0%, #fdea73 15%, #fbdb0f 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbdb0f', endColorstr='#fbdb0f', GradientType=0);
  /* IE6-9 */
}

meter[value="2"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #5dd879 0%, #94d8a6 15%, #5dd879 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="3"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #28a745 0%, #5dd879 15%, #28a745 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}

meter[value="4"]::-moz-meter-bar {
  background: -moz-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00bc25 0%, #28a745 15%, #00bc25 40%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$success', endColorstr='$success', GradientType=0);
  /* IE6-9 */
}
</style>
