<template>
  <b-modal ref="seller-information-modal" id="seller-information-modal" size="xl" centered hide-footer hide-header class="p-0">
    <template #default="{ close }">
      <div class="container">
        <a @click="close()" class="position-absolute close">
          <i class="fa-solid fa-xmark text-secondary" aria-hidden="true" />
        </a>
        <div class="row pt-4">
          <div class="col-12 justify-content-center text-center">
            <b-img v-if="user.photo_path !== null" rounded="circle" :src='formatImageIcon(user.photo_path)' class="shadow profile" />
            <span v-else class="fa-stack fa-5x" style="z-index: 1">
              <i class="fa-solid fa-circle fa-stack-2x text-gray" />
              <i class="fa-solid fa-user fa-stack-1x text-light" />
            </span>
            <h2 class="font-weight-bold m-0 mt-2 text-denim">{{ sellers[selected].username }}</h2>
            <span class="font-weight-bold text-denim">{{ sellers[selected].title }}</span>
          </div>
          </div>
          <div class="row text-center m-0 mt-3 mb-3 m-md-3">
            <div class="col-12" v-html="sellers[selected].description" />
          </div>
          <div class="row bg-gray">
            <div class="col-12 text-center">
              <h4 class="font-weight-bold m-4">{{ $t('meet_the_sellers.best_tasks', { username: sellers[selected].username }) }} </h4>
            </div>
            <div class="col-12">
              <div class="container-fluid featuredTasks featured-tasks" v-if="!preLoader">
                <Carousel :nav="true" :dots="false" :items="4" :responsive="{0:{items:1, dots:true}, 990:{items:3},1200:{items:4}}" :margin="20" class="card-holder">
                  <div class="card-item justify-content-center" v-for="(task, index) in tasks" v-bind:key="index">
                    <Card :isFeaturedTask="true"
                          :carouselImage="task"
                          :username="user.username"
                          :user_rating="user.review_average"
                          :user_count="user.review_count"
                          :user_online="isUserOnline()"
                          :imageIcon="user.photo_path"
                          :slug="task.slug"
                          :description="task.title"
                          :rating="task.review_average"
                          :count="task.review_count"
                          :price="task.packages[0].price"
                          :deliveryTime="task.packages[0].delivery"
                          :position="index + 1"
                          :sellerInfo="user"
                          :hash="task.hash"
                          :select="task.favourite"
                          location="meet-the-sellers"
                          :primaryCategorySlug="task.categories[0].slug"
                          :isHeroTask="task.hero"
                    />
                  </div>
                </Carousel>
              </div>
              <div class="d-flex mt-1 mb-1 justify-content-center" v-else>
                <b-spinner label="Loading" />
              </div>
            </div>
            <div class="col-12 text-center">
              <b-button class="btn btn-primary mb-4 mt-4 close-button w-100" @click="close()">{{ $t('close_button') }}</b-button>
            </div>
          </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ApiService from '../../apiService'
import Carousel from 'v-owl-carousel'
import Card from '../Task/Card'
import helper from '../../helper'

export default {
  name: 'SellerInformationModal',
  components: { Carousel, Card },
  data () {
    return {
      selected: null,
      sellers: {
        'ivanstudio': {
          'username': 'Ivanstudio',
          'title': this.$t('meet_the_sellers.sellers.ivanstudio.title'),
          'description': '<p>' + this.$t('meet_the_sellers.sellers.ivanstudio.description.section_1', { username: 'Ivanstudio' }) + '</p>' +
            '<p class="font-weight-bold font-italic">“We have a great passion for what we do, and we truly enjoy each task as if we were doing it for ourselves,” said George. “We do as many revisions as necessary until the completed task looks exactly like our clients asked”.</p>' +
            '<p>' + this.$t('meet_the_sellers.sellers.ivanstudio.description.section_2') + '</p>' +
            '<p>' + this.$t('meet_the_sellers.sellers.ivanstudio.description.section_3', { link: 'https://anytask.com/meet-the-sellers/ivanstudio' }) + '</p>'
        },
        'writingpro': {
          'username': 'Writingpro',
          'title': this.$t('meet_the_sellers.sellers.writingpro.title'),
          'description': '<p>' + this.$t('meet_the_sellers.sellers.writingpro.description.section_1', { username: 'Writingpro' }) + '</p>' +
            '<p>' + this.$t('meet_the_sellers.sellers.writingpro.description.section_2', { link: 'https://techcabal.com/2020/09/11/techcabal-daily-paypal-in-nigeria-no-but-theres-more/' }) + '</p>' +
            '<p class="font-weight-bold font-italic">"Freelancing has impacted my life significantly in different ways. It has made me who I am today. I am a blogger, investor, university graduate. And all the while, I have not lost sight of my farming roots and philanthropic inclines."</p>' +
            '<p>' + this.$t('meet_the_sellers.sellers.writingpro.description.section_3', { link: 'https://news.anytask.com/anytask-has-impacted-my-life-with-knowledge-upskilling-and-earnings-writingpro' }) + '</p>'
        },
        'helion': {
          'username': 'Helion',
          'title': this.$t('meet_the_sellers.sellers.helion.title'),
          'description': '<p>' + this.$t('meet_the_sellers.sellers.helion.description.section_1', { username: 'Helion' }) + '</p>' +
            '<p>' + this.$t('meet_the_sellers.sellers.helion.description.section_2') + '</p>' +
            '<p class="font-weight-bold font-italic">“AnyTask is very user friendly; the payment happens in minutes after the buyer approves your work and, of course, the main thing I like is that there are no fees or commissions for freelancers.”</p>' +
            '<a href="https://news.anytask.com/anytask-freelancer-helion-is-an-example-of-the-talent-and-skill-that-compel-buyers-to-come-back-for-more" target="_blank">' + this.$t('meet_the_sellers.sellers.helion.description.section_3') + '</a>'
        }
      },
      preLoader: true,
      tasks: [],
      user: null
    }
  },
  methods: {
    open (key) {
      this.selected = key

      if (!this.alreadyBeenOpened()) {
        this.resetData()
        this.getUserTask()
      } else {
        this.$refs['seller-information-modal'].show()
      }
    },
    getUserTask () {
      ApiService.getTasksByUser(this.selected).then(response => {
        this.tasks = response.data.data.gigs.slice(0, 10)
        this.user = response.data.data.user
        this.preLoader = false
        this.$refs['seller-information-modal'].show()
      }).catch()
    },
    alreadyBeenOpened () {
      if (this.user === null) {
        return false
      }
      return this.user.username === this.selected
    },
    resetData () {
      this.user = null
      this.tasks = []
      this.preLoader = true
    },
    formatImageIcon (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    isUserOnline () {
      return this.user.last_active ? helper.isUserOnline(this.user.last_active) : false
    }
  }
}
</script>

<style lang="scss">

#seller-information-modal {
  .modal-body {
    padding: 0
  }
  .owl-carousel {
    .owl-nav {
      .owl-next, .owl-prev {
        position: absolute;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 5px !important;
        background-color: transparent;
        top: calc(50% - 30px);
      }
    }
  }
  .owl-dots {
    margin-top: 10px;
  }
}

@include sm-up {
  #seller-information-modal {
    .modal-dialog {
      max-width: 500px;
    }
    .close-button {
      width: auto !important;
    }
  }
}

</style>

<style lang="scss" scoped>
  .profile {
    width: 150px;
    height: 150px;
    border: white solid;
  }

  .text-denim {
    color: $brand-denim;
  }

  .close {
    right: 20px;
    top: 20px;
    z-index: 99;
  }
</style>
